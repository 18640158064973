/*
* Easy peesy productions (http://easypeesy.info)
* Code licensed under the Apache License v2.0.
* For details, see http://www.apache.org/licenses/LICENSE-2.0.
*/

//jQuery for page scrolling feature - requires jQuery Easing plugin
$(document).ready(function() {
    //var bootstrapButton = $.fn.button.noConflict() // return $.fn.button to previously assigned value
    //$.fn.bootstrapBtn = bootstrapButton
  $('.page-scroll a').bind('click', function(event) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: $($anchor.attr('href')).offset().top - 60
    }, 1000, 'easeInOutExpo');
    event.preventDefault();
  });
});

// Highlight the top nav as scrolling occurs
$(document).ready(function() {
  $('body').scrollspy({
    target: ('.navbar-fixed-top'),
      offset: 61
  })

  // Closes the Responsive Menu on Menu Item Click
  $('.navbar-collapse ul li a').click(function() {
    $('.navbar-toggle:visible').click();
  });

  //$('#domainname').tooltip('toggle').tooltip('hide');
  //$('#webhosting').tooltip('toggle').tooltip('hide');
});

/*visible-lg visible-md visible-xs visible-sm

$(document).ready(function() {
  $('#scroll-top-button-switch-class').viewportChecker({
    classToAdd: 'visible-lg visible-md visible-sm visible-xs',
    classToRemove: 'hidden-lg hidden-md hidden-sm hidden-xs',
    offset: 500,
  });
});

$(document).ready(function() {
  $('#scroll-top-button-switch-class').viewportChecker({
    classToRemove: 'visible-lg visible-md visible-sm visible-xs',
    classToAdd: 'hidden-lg hidden-md hidden-sm hidden-xs',
    offset: 500,
  });
});

/*
$(document).ready(function() {
  $('#aud199').viewportChecker({
    classToAdd: 'visible animated fadeInLeft',
    offset: 100,
  });

  $('#aud399').viewportChecker({
    classToAdd: 'visible animated fadeIn',
    offset: 100,
  });

  $('#aud699').viewportChecker({
    classToAdd: 'visible animated fadeIn',
    offset: 100,
  });

  $('#aud499').viewportChecker({
    classToAdd: 'visible animated fadeInRight',
    offset: 100,
  });

  $('#note').viewportChecker({
    classToAdd: 'visible animated bounceInLeft',
    offset: 100,
  });
});


$(document).ready(function() {
  $('#talk').viewportChecker({
    classToAdd: 'visible animated bounceInLeft',
    offset: 100,
  });
});
*/

/*$(document).ready(function() {
  $('.panel').addClass('hidden').viewportChecker({
      classToAdd: 'visible animated fadeIn',
      offset: 0,
    });
});*/
