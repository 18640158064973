// on page-scroll animate navbar height by applying basically padding to css
$(window).scroll(function () {
    var scrollh = $(this).scrollTop();
    console.log(scrollh);
    if (scrollh <= 5) {
        $("#brandlogo").css({
            'height': '100px'
        });
        //$("#brandlogo").attr({
        //    'src': '{{ asset(img/L8studioWhite.png) }}',
        //});
        $("#navhome .navbar-brand").css({
            'padding': '0 15px'
        });
        $("#navhome .navbar-nav").css({
            'margin': '32px'
        });
        $("#navhome .navbar-default .navbar-nav > li > a").css({
            'color' : '#f2f2f2'
        });
        $('#navhome.navbar-fixed-top').css({
            //'background-color': 'transparent',
            //'background': 'transparent',
            'background': 'inherit'
        });
        $("#navsocials").addClass('hidden').removeClass('visible');
    }
    else {
        $("#brandlogo").css({
            'height':'60px'
        });
        //$("#brandlogo").attr({
        //    'src': '{{ asset(img/L8studioWhite.png) }}',
        //});
        $("#navhome .navbar-brand").css({
            'padding': '0'
        });
        $("#navhome .navbar-nav").css({
            'margin': '15px 0 0 0'
        });
        $("#navhome .navbar-default .navbar-nav > li > a:hover, #navhome .navbar-default .navbar-nav > li > a:focus").css({
            'color' : '#ffffff'
        });
        $('#navhome.navbar-fixed-top').css({
            //'background-color': 'rgba(78, 93, 108, 0.77)',
            'background-color': 'rgba(0, 0, 0, 0.95)'
            //'background-color': 'rgba(255, 255, 255, 0.95)',
            //'background-color': '#00B5F2',
            //'background-color': '#2B3E50',
            //'background-color': '#C9D9E8',
            //'background-image': 'linear-gradient(rgba(43, 62, 80, 0.7), rgba(43, 62, 80, 0.7) 100%, rgb(25, 26, 29))',
            //'background-color' : '#2B3E50',
            //'background-image': 'linear-gradient(rgba(43, 62, 80, 0.7), rgba(43, 62, 80, 0.7) 100%, rgb(25, 26, 29))',
            //'background-image': 'linear-gradient(rgba(38, 61, 84, 0.8), rgba(68, 131, 191, 0.8) 100%, rgb(25, 26, 29))',
            //'background-image': 'linear-gradient(#263D54, #4483BF 100%, rgb(25, 26, 29))',
        });
        //$("#nav .container").css({
        //    'margin':'-7.5px auto',
        //});
        $("#navsocials").removeClass('hidden').addClass('visible');
    }
});


$(document).ready(function () {
    var scrollh = $(this).scrollTop();
    if (scrollh > 5) {
        $("#brandlogo").css({
            'height':'60px'
        });
        $("#navhome .navbar-brand").css({
            'padding': '0'
        });
        $("#navhome .navbar-nav").css({
            'margin': '15px 0 0 0'
        });
        $("#navhome .navbar-default .navbar-nav > li > a:hover, #navhome .navbar-default .navbar-nav > li > a:focus").css({
            'color' : '#ffffff'
        });
        $('#navhome.navbar-fixed-top').css({
            'background-color': 'rgba(0, 0, 0, 0.95)'
        });
        $("#navsocials").removeClass('hidden').addClass('visible');
    }
});

$(document).ready(function(){
    var scrollh = $(window).scrollTop();
    if (scrollh == 0) {
        $('.navbar-toggle').click(function () {
            $('#navhome.navbar-fixed-top').css({
                'background-color': 'rgba(0, 0, 0, 0.95)'
            });
        });
    }
});

$(window).scroll(function() {
    var scrollh = $(this).scrollTop();
    if (scrollh > 50) {
        //$(".scroll-top").addClass('animated bounceInRight visible-lg visible-md visible-sm visible-xs').removeClass('hidden bounceOutRight');
        $(".scroll-top").addClass('bounceInRight').removeClass('bounceOutRight');
    }
    else {
        //$(".scroll-top").addClass('hidden bounceOutRight').removeClass('bounceInRight visible-lg visible-md visible-sm visible-xs');
        $(".scroll-top").addClass('bounceOutRight').removeClass('bounceInRight');
        //$("#nav .container").css({
        //    'margin':'-7.5px auto',
        //});
    }
});