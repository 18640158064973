$(document).ready(function(){
        $(".group1").colorbox({rel:'group1'});
        $(".group1").colorbox({maxWidth:'99%', maxHeight:'99%'});
        $(".group2").colorbox({rel:'group2', transition:"fade"});
        $(".group3").colorbox({rel:'group3', transition:"none", width:"75%", height:"75%"});
        $(".group4").colorbox({rel:'group4', slideshow:true});
        $(".ajax").colorbox();
        //$(".youtube").colorbox({iframe:true, innerWidth:640, innerHeight:390});
        //$(".youtube").colorbox({iframe:true, innerWidth:'95%', innerHeight:'92%'});
        //dont use youtbe class bcoz conflcits with fa youtueb:hover colors in local.css
        $(".youtubecb").colorbox({maxWidth:'99%', maxHeight:'99%', href:function(){
                var videoId = new RegExp('[\\?&]v=([^&#]*)').exec(this.href);
                if (videoId && videoId[1]) {
                    return 'http://youtube.com/embed/'+videoId[1]+'?rel=0&wmode=transparent';
                }
            }
        });
        $(".vimeo").colorbox({iframe:true, innerWidth:500, innerHeight:409});
        $(".iframe").colorbox({iframe:true, width:"80%", height:"80%"});
        $(".inline").colorbox({inline:true, width:"50%"});
        $(".callbacks").colorbox({
            onOpen:function(){ alert('onOpen: colorbox is about to open'); },
            onLoad:function(){ alert('onLoad: colorbox has started to load the targeted content'); },
            onComplete:function(){ alert('onComplete: colorbox has displayed the loaded content'); },
            onCleanup:function(){ alert('onCleanup: colorbox has begun the close process'); },
            onClosed:function(){ alert('onClosed: colorbox has completely closed'); }
        });

        $('.non-retina').colorbox({rel:'group5', transition:'none'})
        $('.retina').colorbox({rel:'group5', transition:'none', retinaImage:true, retinaUrl:true});

        //Example of preserving a JavaScript event for inline calls.
        $("#click").click(function(){
            $('#click').css({"background-color":"#f00", "color":"#fff", "cursor":"inherit"}).text("Open this window again and this message will still be here.");
            return false;
        });
    });

$(window).load(function () {
    setInterval(function () {
        $('#caret').toggleClass('animated flash');
    }, 2000);
});

$(document).ready(function(){
    var images= new Array(9);
    var interval = 5500; //3.0 secs
    var currentImage;
    var counter = 0;
    var imgList;

    imgList = [
        "img/renders/fadedbg/image01.jpg",
        "img/renders/fadedbg/image02.jpg",
        "img/renders/fadedbg/image03.jpg",
        "img/renders/fadedbg/image04.jpg",
        "img/renders/fadedbg/image05.jpg",
        "img/renders/fadedbg/image06.jpg",
        "img/renders/fadedbg/image07.jpg",
        "img/renders/fadedbg/image08.jpg",
        "img/renders/fadedbg/image09.jpg"
    ];

    function loadImages(){
        for(var i=0; i < images.length; i++){
            images[i] = new Image();
            //images[i].style.backgroundImage = "url(" + imgList[i] + ")";
            //images[i].src = "url(" + imgList[i] + ")";
            images[i].src = imgList[i];
            //images[i] = "url(" + imgList[i] + ")";
            console.log("thumbs loaded!");
        }
    }

    loadImages();
    $("#wrapperhome").delay(4000).fadeOut(1500);

    function bgchange() {
        counter++;
        if(counter >= images.length) {
            counter = 0;
        }
        //currentImage = images[counter].style.backgroundImage;
        //currentImage = images[counter].src;
        currentImage = "url('" + images[counter].src + "')";
        //currentImage = images[counter];
        $("#wrapperhome").css({
            //'background': currentImage + ' no-repeat center center fixed',
            //'background': "url(" + imgList[i] + ") no-repeat center center fixed",
            //'background': "url(" + currentImage + ") no-repeat center center fixed",
            'background-image': currentImage
        }).fadeIn(1500).delay(2500).fadeOut(1500);
    }

    //option one to avoid inactive tab to screw animation intervals
    setInterval(function() {
        $("#wrapperhome").stop(true,true);
        bgchange();
    }, interval);
});

//$(document).ready(function(){
//        var images= new Array(9);
//        var imgList;
//
//        imgList = [
//            "img/renders/fullsize/image01.jpg",
//            "img/renders/fullsize/image02.jpg",
//            "img/renders/fullsize/image03.jpg",
//            "img/renders/fullsize/image04.jpg",
//            "img/renders/fullsize/image05.jpg",
//            "img/renders/fullsize/image06.jpg",
//            "img/renders/fullsize/image07.jpg",
//            "img/renders/fullsize/image08.jpg",
//            "img/renders/fullsize/image09.jpg"
//        ];
//
//        function loadFullSizeImages(){
//            for(var i=0; i < images.length; i++){
//                images[i] = new Image();
//                images[i].style.backgroundImage = "url(" + imgList[i] + ")";
                //images[i].src = imgList[i];
                //images[i].src = "{{ secure_url('" + imgList[i] + "') }}";
                //console.log("images loaded!");
            //}
        //}
        //loadFullSizeImages();
//});

$(document).ready(function(){
    //$('#portfolio3 .group1 img').hover(function(){
    //    $('#showimage').find('img').attr('src', this.src);
    //});

    $('#houzz').hover(function(e){
        e.preventDefault();
        $('#houzzslideshow').addClass('visible-lg visible-md hidden-sm hidden-xs').removeClass('hidden');
    //},function() {
    //    $('#houzzslideshow').addClass('hidden hidden-lg hidden-md hidden-sm hidden-xs').removeClass('visible-lg visible-md hidden-sm hidden-xs');
    });

    $('#houzzcloser').click(function(e){
        e.preventDefault();
        $('#houzzslideshow').removeClass('visible-lg visible-md hidden-sm hidden-xs').addClass('hidden');
    //},function() {
    //    $('#houzzslideshow').addClass('hidden hidden-lg hidden-md hidden-sm hidden-xs').removeClass('visible-lg visible-md hidden-sm hidden-xs');
    });
});

$('document').ready(function(){
    $('#submitbutton').click(function(e){
        $(this).html("<i class='fa fa-spinner fa-spin'></i>&nbspWait!").attr('disabled', true);
        e.preventDefault();
        //var formdata = $('#contactusform').serializeArray();
        var name = $('#name').val();
        var email = $('#email').val();
        var phone = $('#phone').val();
        var message = $('#message').val();
        var _token = $('input[name=_token]').val();
        var url = $('#contactusform').attr('action');
        var target = $('#success');
        //target.html = "";
        //if (input) {
          $.ajax({
            //url: host+url,
            url: url,
            type: 'POST',
            //dataType: 'html',
            dataType: 'json',
            //data: {param1: 'value1'},
            data: {name:name, email:email, phone:phone, message:message, _token:_token}
            //data: formdata
            //data: 'formdata='+formdata
          })
          .done(function(data) {
            //var obj = json.parse(msg);
            //var obj = JSON.stringify(data);
            var success = data.responseJSON;
            //var successHtml = " ";
            //$.each( errors, function( key, value ) {
            //    successHtml += "<div class='alert alert-success'><button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;</button><p>"+ value[0] + "</p></div>"; //showing only the first error.
            //});
            //target.html(successHtml);
            target.html("<div class='alert alert-success'><button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;</button><p>" + data.msg + "</p></div>");
            //$('#submitbutton').html("<i class='fa fa-check'></i>&nbspDone!").addClass('disabled').attr('disabled', true);
            $('#submitbutton').html("<i class='fa fa-check'></i>&nbspDone!").attr('disabled', true);
                  //return true;
          })
          .fail(function(data) {
            //var obj = JSON.parse(msg);
            //var obj = JSON.stringify(messages);
            var errors = data.responseJSON;
            var errorsHtml = " ";
            $.each( errors, function( key, value ) {
                errorsHtml += "<div class='alert alert-danger'><button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;</button><p>"+ value[0] + "</p></div>"; //showing only the first error.
            });
            target.html(errorsHtml);
            $('#submitbutton').html("<i class='fa fa-coffee'></i>&nbspResend").attr('disabled', false);
          })
          .always(function() {
            console.log("complete");
          });
        //}
      });
});

$(window).load(function(){
    var $container = $('#thumbs');
    $container.isotope({
        filter: '*',
        animationOptions: {
            duration: 750,
            easing: 'ease-in-out',
            queue: false
        }
    });

    $('.portfolioFilter button').click(function(){
        $('.portfolioFilter .current').removeClass('current btn-black').addClass('btn-white');
        $(this).addClass('current btn-black').removeClass('btn-white');

        var selector = $(this).attr('data-filter');
        $container.isotope({
            filter: selector,
            animationOptions: {
                duration: 750,
                easing: 'ease-in-out',
                queue: false
            }
         });
         return false;
    });
});


$(document).ready(function() {
    $("#testimonial").owlCarousel({
    navigation : false, // Show next and prev buttons
    autoPlay: 3000, //Set AutoPlay to 3 seconds
    items:3,
    slideSpeed : 300,
    paginationSpeed : 400,
    singleItem:true
    });
});

/* Every time the window is scrolled ... */
//$(window).scroll( function(){
    /* Check the location of each desired element */
    //$('.opacity0').each( function(i){
    //    var bottom_of_object = $(this).offset().top + $(this).outerHeight();
    //    var bottom_of_window = $(window).scrollTop() + $(window).height();
        /* If the object is completely visible in the window, fade it in */
        //if( bottom_of_window > bottom_of_object ){
        //    $(this).animate({
        //        'opacity':'1',
        //        'filter': 'alpha(opacity=100)',
        //        '-moz-opacity-': '1.0'
        //    }, 300);
        //}
    //});
//});

//(function($) {
//  var cache = [];
//  // Arguments are image paths relative to the current page.
//  $.preLoadImages = function() {
//    var args_len = arguments.length;
//    for (var i = args_len; i--;) {
//      var cacheImage = document.createElement('img');
//      cacheImage.src = arguments[i];
//      cache.push(cacheImage);
//    }
//  }
//})(jQuery)

//$(document).ready(function(){
//    $('#melanie').mouseover(function () {
//        //var src = $(this).attr("src").match(/[^\.]+/) + "melanie2.png";
//        var src = $(this).attr("src").replace("/img/team/melanie.jpg", "/img/team/melanie2.png");
//        $(this).attr("src", src);
//    })
//    .mouseout(function() {
//        var src = $(this).attr("src").replace("/img/team/melanie2.png", "/img/team/melanie.jpg");
//        $(this).attr("src", src);
//    });
//});


